<script>

import API from "../../../../../plugins/axios-method";
import LoadingModal from "../../../../loading-modal.vue";

export default {
  name: 'search-attribute-table',
  components: {LoadingModal},
  props: {
    searchAttributes: {
      type: Array,
      required: true,
      default(){
        return []
      }
    },
  },
  data(){
    return {
      showDataLoader: false
    }
  },
  methods: {
    formatPhoneNumber(phoneNumber){
      return phoneNumber && phoneNumber.split('237')[1]
    },
    openUpdateModal(s){
      this.$emit('openUpdateModal', s)
    },
    updateStatus(id,status){

      const confirmation = confirm("Voulez-vous vraiment changer le statut ?")

      this.showDataLoader = true

      if (!confirmation) return window.location.reload()

      API.patch(`/super_admin/search_attributes/${id}/status`, {
        status: Number(!status)
      })
          .then(() => {

          })
          .catch(error => {
            // window.location.reload()
            console.log(error)
          })
          .finally(() => {
            this.showDataLoader = false
          })
    },
  }
}
</script>

<template>
  <div class="table-responsive">
    <loading-modal v-model="showDataLoader" />
    <table class="table table-hover table-striped align-middle table-nowrap mb-0">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nom</th>
        <th scope="col">Status</th>
        <th scope="col">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(s, index) in searchAttributes" :key="index">
        <td >{{index + 1}}</td>
        <td>{{ s.name }}</td>
        <td>
          <div class="form-check form-switch">
            <input class="form-check-input" @change="updateStatus(s.uniqId, s.status)" type="checkbox" role="switch" :id="'SwitchCheck'+ index" :checked="s.status">
          </div>
        </td>
       <td>
         <div class="hstack gap-3 fs-15">
           <a href="javascript:void(0);" role="button" @click="openUpdateModal(s)" class="link-primary"><i class="bx bx-pencil"></i></a>
         </div>
       </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>