<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import Axios from "@/plugins/axios";
import DataLoader from "@/components/data-loader.vue";
import SearchAttributeFormModal
  from "@/components/dashboard/parameters/search/search-attribute/search-attribute-form-modal.vue";
import TextModal from "@/components/dashboard/text-modal.vue";
import Pagination from "@/components/dashboard/Pagination.vue";
import {inject} from "vue";
import SearchAttributeTable
  from "@/components/dashboard/parameters/search/search-attribute/search-attribute-table.vue";

export default {
  page: {
    title: "Parameètres",
    meta: [{ name: "description", content: 'Gestion des parametre' }],
  },
  components: {
    SearchAttributeTable,
    Pagination,
    SearchAttributeFormModal,
    DataLoader,
    Layout,
    PageHeader,
    TextModal
  },
  setup(){
    const globals = inject("globals");

    return {globals}
  },
  data() {
    return {
      searchAttributes: [],
      showDataLoader: true,
      entityFormModal: false,
      productDescriptionModal: false,
      productDescription: null,
      activeEntity: null,
      next: null,
      previous: null,
      page: 1,
      totalItems: 0,
      query: '',
      url: ''
    }
  },
  mounted() {
    this.initUrl()
    this.getSearchAttributes()
  },
  methods: {
    openAddEntityFormModal(){
      this.entityFormModal = true
    },
    openUpdateModal(s){
      this.activeEntity = s
      this.entityFormModal = true
    },
    closeModal(type){
      switch (type) {
        case 'search-attribute-form':
          this.activeEntity = null
          this.entityFormModal = false
          break
        case 'user-update-form':
          this.activeEntity = null
          this.entityFormModal = false
          break
        case 'text-modal':
          this.productDescription = null
          this.productDescriptionModal = false
          break
      }
    },
    updateTable(product){
      this.searchAttributes = this.searchAttributes.filter(p => p.id !== product.id)
      this.searchAttributes.unshift(product)
    },
    getSearchAttributes(){
      Axios.get(this.url)
          .then(response => {
            this.setPagination(response)
            response.data["hydra:member"].map(p => {
              this.searchAttributes.push(p)
            })

          })
          .catch(() => {})
          .finally(() => {
            this.showDataLoader = false
          })
    },
    initUrl(){
      this.url = 'super_admin/search_attributes'
    },
    setPagination(response){
      if (response.data["hydra:view"]) {
        if (response.data["hydra:view"]["hydra:next"]){

          this.totalItems = response.data["hydra:totalItems"]
          this.next = response.data["hydra:view"]["hydra:next"]
          this.previous = response.data["hydra:view"]["hydra:previous"]
        }
      }
    },
    nextPage(){
      this.setCurrentPage(this.next)
      this.searchAttributes = []
      this.getSearchAttributes()
    },
    previousPage(){
      this.setCurrentPage(this.previous)
      this.searchAttributes = []
      this.getSearchAttributes()
    },
    navigate(page){
      this.page = page
      this.searchAttributes = []
      this.showDataLoader = true
      this.initUrl()
      this.getSearchAttributes()
    },
    setCurrentPage(url){
      const currentPage = Number(url.split('page=')[1])
      if (currentPage !== this.page) this.page = currentPage
      this.url = url.split('api/')[1]
      this.showDataLoader = true
    },
    search(query){
      this.query = query
      this.showDataLoader = true
      this.searchAttributes = []
      this.initUrl()
      this.getSearchAttributes()
    },
    openProductDescriptionModal(p){
      this.productDescription = p.description
      this.productDescriptionModal = true
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Attributs de Recherche'"
        :button-title="'+ Attribut'"
        :show-search="false"
        :show-add-button="globals.roles.IS_SUPER_ADMIN"
        @clickButton="openAddEntityFormModal"
        @submitSearch="search"
    />
    <search-attribute-form-modal
        @onModalClose="closeModal"
        @entityCreated="updateTable"
        :activeEntity="activeEntity"
        :title="'Créer Un Attribut de Recherche'"
        v-model="entityFormModal"
    />
    <TextModal
        @onModalClose="closeModal"
        :content="productDescription"
        v-model="productDescriptionModal"
    />
    <DataLoader v-if="showDataLoader"/>
    <section>
      <div class="" v-if="searchAttributes.length > 0">
        <div class="card">
          <div class="card-body">
            <search-attribute-table
                :search-attributes="searchAttributes"
                @openUpdateModal="openUpdateModal"
            />
            <Pagination
                :next="next"
                :previous="previous"
                :currentPage="page"
                :totalItems="totalItems"
                @navigate="navigate"
                @nextPage="nextPage"
                @previousPage="previousPage"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>